.menu {
    height: 72px !important;
    width: 100%;
}

.menu.top {
    background-color: transparent !important;
}

.menu.top a, .menu.top i {
    color: white;
}

.menu.top > .logo-2 {
    display: block;
}

.menu.top > .logo-1, .menu-toggle {
    display: none;
}

.menu-globe, .menu-phone {
    display: none;
    font-size: 19px;
    color: #305C6E;
    transition: opacity 0.2s;
}

.menu-logo {
    margin: 0 39px;
}

/*#mobile-header-logo {*/
/*    display: none;*/
/*}*/

.menu-lines {
    height: 10px;
}

.logo-2, .logo-3, .logo-4 {
    display: none;
}

.menu-center {
    margin-left: calc(50% - 774px / 2 - 125px);
}

.nav-link {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #305C6E;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.menu-center .nav-link {
    letter-spacing: 2px;
}

.nav-link i {
    font-size: 19px;
}

.navbar-button {
    padding: 16px 20px;
    text-align: center;
    background: linear-gradient(90deg, #C6B166 0%, #A9955E 100%);
    border-radius: 40px;
}

.language-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-map {
    width: 50%;
    margin-left: 8rem;
    display: flex;
    align-items: center;
}

.header-regions {
    font-size: 13px;
    font-weight: 500;
}

.nav-item-dropdown:hover, .nav-item-dropdown:focus {
    background: rgba(74, 78, 82, 0.02);
    box-shadow: inset 0 -1px 0 #A9955E;
    cursor: pointer;
}

.nav-item-dropdown:hover > .nav-link, .nav-item-dropdown:focus > .nav-link {
    color: #C5B065 !important;
}

.dropdown-menu {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #305C6E;
    border: none;
    border-radius: 0;
}

.dropdown-menu a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.8);
}

.dropdown-menu a:hover, .dropdown-menu a.active {
    color: #C5B065;
}

.menu-regions a:hover, .menu-regions a.active {
    color: #C5B065 !important;
}

.header-regions {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.header-regions a {
    color: #5A5A5A;
}

.header-regions-languages {
    margin-bottom: 26px;
}

.header-regions-languages a {
    color: #305C6E;
}

.header-bottom-right-triangle-container {
    position: absolute;
    right: 0;
    bottom: 0;
}

.header-bottom-right-triangle {
    border-bottom: solid var(--main-blue);
    border-left: solid transparent;
}

.header-top-right-triangle-container {
    position: absolute;
    right: 0;
    top: 0;
}

.header-top-right-triangle {
    border-top: solid var(--main-yellow);
    border-left: solid transparent;
}

.header-bottom-left-triangle-container {
    position: absolute;
    left: 0;
    bottom: 0;
}

.header-bottom-left-triangle {
    border-bottom: solid var(--main-blue);
    border-right: solid transparent;
}

.header-top-left-triangle-container {
    position: absolute;
    left: 0;
    top: 0;
}

.header-top-left-triangle {
    border-top: solid var(--main-blue);
    border-right: solid transparent;
}

.header-bottom-right-triangle, .header-bottom-left-triangle, .header-top-right-triangle, .header-top-left-triangle {
    height: 0;
    width: 0;
}

.header-regions-container {
    width: 50%;
    margin-right: 10rem;
    padding: 1.5rem 0;
}

.header-dropdown-container {
    margin: 0 auto;
}

.header-dropdown-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #5A5A5A !important;
    padding-bottom: 24px;
}

.link-hover:hover {
    text-decoration: underline !important;
}
.header-dropdown-title-link:hover:after{
    content: url(/images/header/golden-arrow.png);
    height: 6px;
    width: 3px;
    padding-left: 15px;
}
.link-hover:hover{
    color: #A9955E !important;
    text-decoration: none !important;
}
@media screen and (min-width: 1025px) {
    .header-dropdown-title-link::after {
        content: url(/images/header/right-arrow.png);
        height: 6px;
        width: 3px;
        padding-left: 15px;
    }
}

.header-dropdown-col-description {
    padding-top: 24px;
    color: #454B51;
    line-height: 21px !important;
    font-family: "Sweet Sans Pro Reg" !important;
}

.header-dropdown-item {
    max-width: 230px;
    min-width: 150px;
    padding: 10px 0;
    font-size: 12px;
    line-height: 14px;
    color: #305C6E;
}

.header-dropdown-item a {
    color: #305C6E;
}

.header-dropdown-title.max-dropdown, .header-dropdown-item.max-dropdown {
    max-width: 202px;
}

.header-dropdown-container#resources {
    max-width: 574px;
}

.header-dropdown-container#about {
    max-width: 392px;
}

.header-dropdown-container#tutors {
    max-width: 763px;
}

.header-dropdown-container#schools {
    max-width: 823px;
}

.toggled {
    height: 100vh;
}

.menu-drill {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-top: 47px;
    width: 100%;
    height: 0;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    transition: height .56s cubic-bezier(0.52, 0.16, 0.24, 1);
    background-color: #FAFAFA;
}

.menu.toggled .menu-drill.mobile-menu, .menu-drill.menu-regions.toggled {
    height: calc(100% - 47px);
    transition: height .56s cubic-bezier(0.52, 0.16, 0.24, 1), margin-top 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);
}

.cancel-search {
    opacity: 0;
    background-color: transparent;
    color: #305C6E;
    transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.menu-drill::-webkit-scrollbar {
    display: none;
}

.menu-drill ul {
    margin: 0;
    padding: 0;
}

.menu-drill li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    position: static;
    height: 60px;
    cursor: pointer;
}

.menu-drill li:has( .menu-item-header) {
    height: 60px;
}

.menu-drill a {
    text-decoration: none;
    color: #305C6E !important;
}

.menu-drill li i {
    color: #9EB3BB;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0 6px;
}

.nav-expand-content {
    position: absolute;
    /*top: 72px;*/
    top: 0;
    left: 0;
    width: 100%;
    /*height: 100%;*/
    background-color: #FFFFFF;
    transform: translateX(100%);
    transition: 0.4s ease-in;
    visibility: hidden;
}

.menu-drill li.active:not(.menu-search) > .nav-expand-content {
    transform: translateX(0);
    visibility: visible;
    z-index: 9;
}

.menu-search .nav-expand-content {
    transform: none;
}

.menu-search.active .nav-expand-content {
    visibility: visible;
    z-index: 10;
}

.menu-search .nav-expand-content li {
    height: 48px !important;
}


.menu-search .nav-expand-content li a {
    width: 100%;
}

.nav-expand-content li {
    justify-content: stretch;
    padding-left: 16px;
}

.menu-search .nav-expand-content {
    height: 100%;
}

.nav-expand-content li:not(:first-child) {
    height: 48px;
}

.nav-expand-content li i {
    order: 0;
    color: #1A5A94;
}

.nav-expand-content .nav-back-link {
    color: #515151;
    width: 100%;
}

.nav-expand-content .menu-item-header {
    width: 100%;
    font-weight: normal;
    text-transform: uppercase;
    color: #868686 !important;
}

.nav-expand-content .menu-item {
    color: #305C6E;
    padding: 0 16px;
    width: 100%;
}

.menu-item-header-container {
    height: 60px !important;
}

.menu-regions .menu-regions-container {
    flex-direction: row-reverse;
}

.menu-regions .header-regions-container {
    width: 100%;
    margin: 0;
    padding: 0 16px;
}

.menu-regions .header-map {
    display: none;
}

.menu-regions .header-regions {
    flex-basis: auto !important;
    justify-content: start !important;
    padding: 21px 16px;
}

.menu-regions .header-regions a {
    font-size: 14px;
    line-height: 17px;
    color: #868686 !important;
}

.menu-regions .row {
    flex-direction: column;
}

.menu-regions .header-regions-languages {
    flex-basis: auto !important;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.menu-regions .header-regions-languages a {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 32px !important;
    font-size: 14px;
    line-height: 17px;
    color: #305C6E !important;
}

.burger-container {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 48px;
    cursor: pointer;
    transform: rotate(0deg);
    transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

#burger {
    width: 16px;
    height: 8px;
    position: relative;
    display: block;
    margin: -4px auto 0;
    top: 50%;
}

#region-close-container {
    display: none;
}

.bar {
    width: 100%;
    height: 1px;
    display: block;
    position: relative;
    background: #305C6E;
    transition: tranform 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: 0s;
}

.menu.top .bar {
    background: #FFF;
}

.bar.topBar {
    transform: translateY(0px) rotate(0deg);
}

.bar.btmBar {
    transform: translateY(6px) rotate(0deg);
}


.menu.toggled .burger-container {
    transform: rotate(90deg);
}

.menu.toggled #burger .bar {
    transition: tranform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: 0.2s;
}

.menu.toggled #burger .bar.topBar {
    transform: translateY(4px) rotate(45deg);
}

.menu.toggled #burger .bar.btmBar {
    transform: translateY(3px) rotate(-45deg);
}

.menu.toggled ul.menu-items {
    -webkit-overflow-scrolling: touch;
    visibility: hidden;
    -webkit-transition: visibility 0s linear 1s;
    transition: visibility 0s linear 1s;
}

.menu.toggled ul.menu-items {
    visibility: visible;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.menu.toggled ul.menu-items > li {
    opacity: 1;
    transform: none;
    transition: opacity .3345s cubic-bezier(0.52, 0.16, 0.52, 0.84) .15s, transform .4669s cubic-bezier(0.52, 0.16, 0.52, 0.84) .108s, -webkit-transform .4669s cubic-bezier(0.52, 0.16, 0.52, 0.84) .108s;
}

nav ul.menu-items > li:nth-child(1) {
    opacity: 0;
    transform: translateY(-44px);
    transition: opacity .3345s cubic-bezier(0.52, 0.16, 0.52, 0.84) .15s, transform .4669s cubic-bezier(0.52, 0.16, 0.52, 0.84) .108s, -webkit-transform .4669s cubic-bezier(0.52, 0.16, 0.52, 0.84) .108s
}

nav ul.menu-items > li:nth-child(2) {
    opacity: 0;
    transform: translateY(-40px);
    transition: opacity .30573s cubic-bezier(0.52, 0.16, 0.52, 0.84) .13667s, transform .45552s cubic-bezier(0.52, 0.16, 0.52, 0.84) .09933s, -webkit-transform .45552s cubic-bezier(0.52, 0.16, 0.52, 0.84) .09933s;
}

nav ul.menu-items > li:nth-child(3) {
    opacity: 0;
    transform: translateY(-36px);
    transition: opacity .28122s cubic-bezier(0.52, 0.16, 0.52, 0.84) .12333s, transform .44574s cubic-bezier(0.52, 0.16, 0.52, 0.84) .09067s, -webkit-transform .44574s cubic-bezier(0.52, 0.16, 0.52, 0.84) .09067s;
}

nav ul.menu-items > li:nth-child(4) {
    opacity: 0;
    transform: translateY(-32px);
    transition: opacity .26098s cubic-bezier(0.52, 0.16, 0.52, 0.84) .11s, transform .43756s cubic-bezier(0.52, 0.16, 0.52, 0.84) .082s, -webkit-transform .43756s cubic-bezier(0.52, 0.16, 0.52, 0.84) .082s;
}

nav ul.menu-items > li:nth-child(5) {
    opacity: 0;
    transform: translateY(-28px);
    transition: opacity .24499s cubic-bezier(0.52, 0.16, 0.52, 0.84) .09667s, transform .43097s cubic-bezier(0.52, 0.16, 0.52, 0.84) .07333s, -webkit-transform .43097s cubic-bezier(0.52, 0.16, 0.52, 0.84) .07333s;
}

nav ul.menu-items > li:nth-child(6) {
    opacity: 0;
    transform: translateY(-24px);
    transition: opacity .23327s cubic-bezier(0.52, 0.16, 0.52, 0.84) .08333s, transform .42598s cubic-bezier(0.52, 0.16, 0.52, 0.84) .06467s, -webkit-transform .42598s cubic-bezier(0.52, 0.16, 0.52, 0.84) .06467s;
}

nav ul.menu-items > li:nth-child(7) {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity .22156s cubic-bezier(0.52, 0.16, 0.52, 0.84) .06999s, transform .42598s cubic-bezier(0.52, 0.16, 0.52, 0.84) .05601s, -webkit-transform .42099s cubic-bezier(0.52, 0.16, 0.52, 0.84) .05601s;
}

.search-container {
    width: 100%;
    visibility: hidden;
    -webkit-transition: background .44s cubic-bezier(0.52, 0.16, 0.24, 1) .1s, visibility 0s linear .5s;
    transition: background .44s cubic-bezier(0.52, 0.16, 0.24, 1) .1s, visibility 0s linear .5s;
}

.menu-search {
    justify-content: stretch !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    height: 40px !important;
}

.menu.toggled .search-container {
    visibility: visible;
    -webkit-transition: background .18s cubic-bezier(0.32, 0.08, 0.24, 1) .18s, visibility 0s linear 0s;
    transition: background .18s cubic-bezier(0.32, 0.08, 0.24, 1) .18s, visibility 0s linear 0s;
}

.menu.toggled .header-right-mobile {
    opacity: 0;
    cursor: auto;
}

.menu-expand-link {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase
}

.nav-back-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #515151;
}

.menu-item, .menu-item-header {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

ul.menu-items {
    /*max-width: 664px;*/
    margin: 0 auto;
}

.search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 16px;

    position: static;
    /*max-width: 584px;*/
    height: 40px;
    left: 16px;
    top: 16px;
    margin: 0 auto;

    background: #FAFAFA;
    border: 1px solid #E2EBEF;
    box-sizing: border-box;
    border-radius: 32px;
}

.search-input-container {
    flex-grow: 1;
    display: flex;
    justify-content: start;
    align-items: center;
}

.search-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-left: 5px;
    border: none;
    flex-grow: 1;
    background: transparent;
    outline: none;
}

.search-close {
    opacity: 0;
    cursor: pointer;
    transition: 0.2s;
}

.menu-collapse {
    height: 100%;
}

@media screen and (max-width: 1415px) {
    #header-phone-number {
        display: none;
    }

    .nav-link .selected-region {
        display: none;
    }

    .navbar-center .nav-item {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .menu-center {
        margin-left: calc(50% - 628px / 2 - 125px);
    }

    .menu-center .nav-link {
        padding: 20px 8px;
        letter-spacing: 0;
    }
}

@media screen and (max-width: 1024px) {
    .menu-toggle, .menu-globe, .menu-phone {
        display: block;
    }

    .menu-collapse {
        display: none !important;
    }

    .menu-logo {
        margin: 0 30px;
    }

    /*#mobile-header-logo {*/
    /*    display: block;*/
    /*}*/

    /*#desktop-header-logo {*/
    /*    display: none;*/
    /*}*/

    .menu {
        height: 56px !important;
    }

    #region-close-container {
        width: 48px;
        height: 56px;
        position: absolute;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        display: none;
        left: 0;
    }

    #region-close {
        top: 50%;
        left: calc(50% - 4px);
        width: 16px;
        height: 8px;
        position: relative;
        margin: auto 0;
        display: block;
    }

    .xbar {
        width: 100%;
        height: 1px;
        display: block;
        background: #305C6E;
    }

    #main-xbar {
        transform: rotate(45deg);
    }

    #second-xbar {
        transform: translateY(-1px) rotate(-45deg);
    }
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 425px) {

}

@media screen and (min-width: 1025px) {
    .menu-toggle, .menu-close, .menu-drill {
        display: none !important;
    }
}

@media screen and (max-width: 1025px) {
    .search-text {
        font-size: 16px;
        line-height: 20px;
    }
}

.header-dropdown-container#schools {
    max-width: 1088px;
}

.header-dropdown-container#tutors {
    max-width: 1088px;
}

.header-dropdown-container#resources {
    max-width: 1088px;
}

.header-dropdown-container#about {
    max-width: 1088px;
}

.header-dropdown-col-container {
    padding-left: 0;
    padding-right: 0;
    max-width: 200px;
}

.header-grid {
    display: grid;
    justify-content: space-evenly;
    justify-items: start;
    align-content: space-evenly;
    align-items: start;
}

#schools .header-grid {
    grid-template-columns: repeat(4, 1fr);
}

#schools .header-dropdown-col-container {
    max-width: 215px;
}

#tutors {
    padding-bottom: 20px;
}

#tutors .header-grid {
    grid-template-columns: repeat(2, 1fr);
}

#tutors .header-dropdown-col-container {
    max-width: 225px;
}

#header-consultants-image {
    position: absolute;
    bottom: 0;
    right: 4rem;
    pointer-events: none;
}

.relative {
    position: relative;
}

#header-tutors-image-1 {
    position: absolute;
    top: -20px;
    left: 270px;
}

@media screen and (min-width: 1025px) and (max-width: 1100px) {
    #header-tutors-image-1 {
        left: 240px;
    }
}

#header-tutors-image-2 {
    position: absolute;
    top: -40px;
    right: 0;
}

#resources {
    padding-bottom: 20px;
}

#resources .header-grid {
    grid-template-columns: repeat(2, 1fr);
    max-width: 520px;
    margin: auto;
}

#resources .header-dropdown-col-container {
    max-width: 230px;
}

#header-resources-image-1 {
    position: absolute;
    top: -5px;
    left: 0;
}

@media screen and (min-width: 1025px) and (max-width: 1100px) {
    #header-resources-image-1 {
        left: -30px;
    }
}

#header-resources-image-2 {
    position: absolute;
    bottom: 20px;
    right: 0;
}

#about {
    padding-bottom: 20px;
}

#about .header-grid {
    grid-template-columns: repeat(2, 1fr);
    max-width: 500px;
    margin-left: auto;
}

#about .header-dropdown-col-container {
    max-width: 210px;
}

#header-about-image {
    position: absolute;
    bottom: 20px;
    left: 0;
}

@media screen and (min-width: 1025px) and (max-width: 1060px) {
    #header-about-image {
        left: -40px;
    }
}
