.footer-container {
    font-family: "Sweet Sans Pro Reg", 'Roboto Thin', sans-serif;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    width: 100%;
    min-width: 300px;
    background: white;
}

.footer-logo-container {
    position: absolute;
    z-index: 999;
    font-size: 25px;
    left: 4vw;
    top: 25px;
}

.footer-details-container {
    position: relative;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    padding: 0 6.8vw 30px 10vw;
}

.footer-columns-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;
}

.footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 30px 4.8vw 0 0;
}

.footer-column p {
    font-size: 12px;
}

.detail-column {
    max-width: 210px;
    /*min-width: 170px;*/
}

.detail-column a:hover{
    color: #A9955E;
}

.detail-column span{
    color: #9EB3BB;
}

.footer-icon-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    max-width: 250px;
    width: 30vw;
    margin-right: -20px;
    margin-top: 29px;
}
.footer-icon{
    color: #9EB3BB !important;
}
.icon-container {
    width: fit-content;
    flex-basis: 20%;
    margin-bottom: 20px;
}
.icon-container a {
    font-size: 20px !important;
}

.footer-container a {
    text-decoration: none;
    margin-top: 15px;
    color: #305C6E;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.footer-container p {
    margin-bottom: 0;
    margin-top: 15px;
}

.footer-title {
    margin-top: 0;
    margin-bottom: 10px;
    color: #454B51;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
}

.footer-bottom {
    height: 44px;
    background: #CED8DD;
    padding: 0 10vw;
    border-top: 1px solid #EEEEEE;
    color: #305C6E;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.external-link-icon {
    font-size: 10px;
}

#footer-terms-container, #footer-terms-mobile-container {
    display: flex;
    gap: 16px;
}

#footer-terms-container a {
    margin-top: 0;
}

#footer-terms-mobile-container {
    display: none;
}

@media screen and (max-width: 1024px) {
    .footer-container {
        align-items: center;
        min-width: auto;
        width: 100vw;
    }

    .footer-logo-container {
        display: flex;
        width: 100%;
        position: static;
        margin: 32px 0;
    }

    .footer-details-container {
        flex-direction: column;
        padding: 0;
        padding-bottom: 30px;
        align-items: center;
        width: 83%;
    }

    .footer-columns-container {
        display: grid;
        grid-template-columns: auto auto;
        grid-row-gap: 50px;
        grid-column-gap: 15px;
        width: 100%;
        justify-content: normal;
    }

    .footer-column {
        margin: 0;
    }

    .detail-column {
        width: auto;
    }
    .detail-column a{
        font-size: 12px;
    }

    .footer-icon-container {
        width: 100%;
        max-width: none;
        margin: 40px 0;
    }

    .icon-container {
        width: fit-content;
    }

    .footer-icon {
        margin: 0;
        width: fit-content;

    }

    .footer-bottom {
        justify-content: center;
        padding: 0;
        width: 100vw;
        background-color: #CED8DD;
        border-top: none;
    }

    #footer-terms-container {
        display: none;
    }

    #footer-terms-mobile-container {
        display: flex;
    }

    .footer-contact-details {
        width: 100%;
        margin-top: 50px;
    }

    .footer-contact-details .detail-column {
        max-width: none;
    }
}
