@import "~bootstrap-icons/font/bootstrap-icons.css";
@import 'header.css';
@import 'footer.css';
@import "/node_modules/intl-tel-input/build/css/intlTelInput.css";


@tailwind base;
@tailwind components;
@tailwind utilities;

.iti__flag {
    background-image: url("/intl-tel/images/vendor/intl-tel-input/build/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("/intl-tel/images/vendor/intl-tel-input/build/flags@2x.png");
    }
}

:root {
    --main-blue: rgba(68, 120, 152, 0.3);
    --main-yellow: rgba(198, 177, 102, 0.8);
    --main-green: #1C5036;
    --main-dark-gray: #92B0B7;
    --main-light-gray: #C1C7CE;
    --main-purple: #462978;
    --main-dark-blue: #315669;
}

h1 {
    font-family: Libre Baskerville;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
}

h2 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
}

h3 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
}

h4 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */
}

h1, h2, h3 {
    color: #454B51;
}

body {
    margin: 0;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    min-width: 375px;
}

.main-container {

    background-color: #F3F5F8;
    background-position: center;
    position: relative;
    z-index: 0;
    overflow-x: hidden;
}

.single-page-container {
    min-height: calc(100vh - 316px);
    display: flex;
    align-items: center;
}

button:hover {
    cursor: pointer;
}

.blue-button {
    background: linear-gradient(110deg, #37687B, #2B4A5A, #2B4A5A, #37687B) left;
    background-size: 300%;
    transition: 1s background-position ease-out;
}

.blue-button:hover {
    background-position: right;
}

.golden-button {
    background: linear-gradient(110deg, #C6B166, #A9955E, #A3905D, #D1B963) left;
    background-size: 300%;
    box-shadow: 0 4px 14px rgba(11, 32, 63, 0.2);
    transition: 1s background-position ease-out;
}

.golden-button:hover {
    background-position: right;
    box-shadow: 0 10px 36px rgba(11, 32, 63, 0.16);
}

.wire-golden-button {
    background: transparent;
    border: 1px solid #A9955E !important;
    color: #A9955E !important;
    white-space: nowrap;
    box-shadow: none;
    transition: 0.8s ease-out;
}

.wire-golden-button:hover {
    background: linear-gradient(110deg, #C6B166, #A9955E);
    color: #FFFFFF !important;
}

h1 {
    margin: 0;
}

.section-container {
    padding-left: 11vw;
    padding-right: 11vw;
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
}

.info-section-container {
    width: 43vw;
}

.input-layout {
    border-radius: 50px;
    border: none;
    padding: 14px 24px;
    color: #ffffff;
    letter-spacing: 2px;
    overflow: hidden;
}

button {
    border-radius: 50px;
    border: none;
    letter-spacing: 2px;
    display: inline-block;
    color: #ffffff;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    text-transform: uppercase;
    width: fit-content;
}

.input-layout:focus {
    outline: none;
}

.triangle {
    width: 0;
    height: 0;
}

.rounded-50 {
    border-radius: 50px;
}

.small-button {
    padding: 14px 24px;
}

.medium-button {
    padding: 16px 32px;
}

.medium-2-button {
    padding: 16px 24px;
}

.big-button {
    padding: 24px 32px;
}

.big-spaced-title {
    line-height: 72px;
}

.spaced-letters {
    letter-spacing: 2px;
}

.before-title {
    color: #305C6E;
    margin-bottom: -3px;
    text-transform: uppercase;
}

.medium-spaced-title {
    line-height: 50px;
    font-size: 44px;
}

.small-spaced-title {
    line-height: 42px;
    font-size: 36px;
}

*, *::before, *::after {
    box-sizing: content-box;
}

.section-title {
    font-family: HCo Chronicle Display, 'Playfair Display', serif;
    font-weight: 600;
    color: #305C6E;
}

.description-paragraph {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
}

.presentation-paragraph-18 {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
}

.presentation-paragraph-20 {
    font-size: 20px;
    line-height: 36px;
    font-weight: 500;
}

.quote-container {
    width: 28vw;
    padding: 40px 30px;
    background-color: white;
    border-radius: 8px;
    font-size: 14px;
    z-index: 999;
}

.percentage-section-container .quote-container {
    width: 33vw;
}

@media screen and (min-width: 1850px) {
    .quote-container {
        width: 25vw;
    }
}

.box-quote {
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    margin-bottom: 24px;
}

.box-signature {
    line-height: 21px;
    font-weight: 700;
    color: #305C6E;
    margin-bottom: 0;
    font-size: 14px;
    text-transform: uppercase;
}

.title-margin-bottom {
    margin-bottom: 30px;
}

.paragraph-margin-bottom {
    margin-bottom: 40px;
}

.custom-carousel-indicators li {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.custom-carousel-indicators .active {
    background-color: #8D9BA7
}

.quote-icon {
    font-size: 40px;
    color: #F0F0F0;
    position: absolute;
    top: 10px;
    left: 15px
}

.small-spaced-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 43px;
}

.blue-text {
    color: #305C6E;
}

.mobile-button {
    width: auto;
}

p {
    color: #454B51;
}

.expertise-card {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    padding: 50px 60px;
    position: relative;
    overflow: hidden;
    z-index: 1200;
}

.expertise-item-width {
    max-width: 430px;
    margin: 12px;
}

.expertise-card p {
    color: #305C6E;
}

.expertise-card-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.expertise-card-description {
    margin-top: 15px;
    margin-bottom: 25px;
}

.expertise-card-more {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    margin: 0;
    margin-right: 10px;
    letter-spacing: 2px;
}

.more-arrow-container:hover {
    color: #A9955E;
}

.more-arrow-container p:hover {
    color: #A9955E;
}

.more-arrow-container i:hover {
    color: #A9955E;
}

.expertise-triangle {
    position: absolute;
    left: 0;
    top: 0;
}

.card-upper-triangle {
    z-index: 999;
    border-bottom: solid rgba(201, 211, 216, 0.75);
    border-left: solid transparent;
    transform: rotate(180deg);
    border-bottom-width: calc(2vw + 20px);
    border-left-width: calc(7vw + 30px);
    mix-blend-mode: multiply;
}

.color-burn-gray-triangle {
    mix-blend-mode: color-burn !important;
}

.card-left-triangle {
    z-index: 900;
    border-left: solid;
    border-bottom: solid transparent;
    transform: rotate(360deg);
    border-left-width: calc(2vw + 20px);
    border-bottom-width: calc(7vw + 30px);
    mix-blend-mode: multiply;
}

.more-arrow-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.purple-triangle {
    border-left-color: rgba(100, 83, 130, 0.7); /*#645382*/
}

.dark-blue-triangle {
    border-left-color: rgba(48, 92, 110, 0.65); /*#305C6E*/
}

.golden-triangle {
    border-left-color: rgba(198, 177, 102, 0.9); /*#C6B166*/
}

.green-triangle {
    border-left-color: rgba(79, 126, 103, 0.65); /*#4F7E67*/
}

.light-blue-triangle {
    border-left-color: rgba(66, 124, 159, 0.75); /*#427C9F*/
}

.news-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
}

.news-container + .subscribe-container {
    margin-top: -50px !important;
}

.news-title-container {
    width: 100%;
}

#news-card-container {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 50px;
    max-width: 1110px;
}

.news-card {
    border-radius: 10px;
    max-width: 360px;
    display: flex;
    align-items: flex-end;
    color: #FFFFFF;
}

.news-card a {
    z-index: 1200;
}

.news-card:hover {
    cursor: pointer;
}

.news-card p {
    color: #FFFFFF;
}

.big-news-card {
    min-height: 474px;
    background: linear-gradient(180deg, rgba(4, 41, 74, 0) 11.98%, rgba(4, 41, 74, 0.2) 77.08%);
    grid-row: 1 / 3;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

#centered-news-card {
    grid-row: 2 / 4;
}

.small-news-card {
    min-height: 237px;
}

.purple-card {
    background: #645382;
}

.gray-card {
    background: #8D9BA7;
}

.card-image-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    border-radius: 10px;
}

.card-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
    transition: transform .4s;
    position: absolute;
    left: 0;
    top: 0;
}

.big-news-card:hover .card-image {
    transform: scale(1.1);
    transform-origin: 50% 50%;
}

#view-all {
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    align-self: center;
    font-weight: 400;
    color: #305C6E;
    cursor: pointer;
    text-decoration: none;
}

#view-all:hover {
    color: #A9955E;
}

.card-text-container {
    color: white;
    padding: 20px 30px;
    z-index: 2000;
    user-select: none;
    z-index: 0;
}

.card-title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    text-transform: capitalize;
}

.text-ellipsis-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.card-date {
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
}

.card-description {
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
}

.card-tag {
    display: inline-block;
    font-size: 12px;

    font-weight: 600;
    line-height: 180%;
    padding: 4px 10px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50px;
    text-transform: capitalize;
}

.card-arrow {
    font-size: 20px;
    font-weight: lighter;
}

.button-arrow {
    font-size: 14px;
    font-weight: lighter;
    padding-left: 5px;
}

.subscribe-container {
    position: relative;

    background-color: #F3F5F8;
    padding-top: 70px;
    padding-bottom: 70px;
}

.single-page-container .subscribe-container {
    flex: 1;
    text-align: center;
}

.single-page-container .subscribe-container .info-section-container {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.single-page-container .subscribe-container form {
    width: 100%;
}

.single-page-container .subscribe-container .email-container {
    width: 100%;
    justify-content: center;
}

#subscription-paragraph {
    margin: 26px 0;
    max-width: 450px;
}

#homepage-subscription-paragraph {
    max-width: 640px;
}

#subscribe-info-mobile-container {
    width: 65%;
}

.email-container {
    display: flex;
    align-items: center;
    width: 85%;
}

#email-input {
    width: 60%;
    margin-right: 10px;
    padding: 14px 24px !important;
    color: #305C6E;
}

#email-input::placeholder {
    color: #305C6E;
}

.subscription-vertical-triangle-container {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
}

.subscription-horizontal-triangle-container {
    position: absolute;
    right: 0;
    bottom: 0;
}

.vertical-triangle {
    z-index: 900;
    border-left-width: calc(5vw + 60px);
    border-left-style: solid;
    border-left-color: transparent;
    border-bottom-style: solid;
}

.subscribe-purple-triangle {
    border-bottom-color: #645382
}

.subscribe-dark-blue-triangle {
    border-bottom-color: #305C6E
}

.subscribe-golden-triangle {
    border-bottom-color: #C6B166
}

.subscribe-green-triangle {
    border-bottom-color: #4F7E67
}

.subscribe-light-blue-triangle {
    border-bottom-color: #427C9F
}

.horizontal-triangle {
    z-index: 999;
    border-left-width: calc(20vw + 80px);
    border-left-style: solid;
    border-left-color: transparent;
    border-bottom-width: calc(5vw + 60px);
    border-bottom-style: solid;
    border-bottom-color: rgba(40, 40, 40, 0.2);
}

#mobile-subscribe-button {
    display: none;
}

.wired-journey {
    width: 100%;
    padding: 60px 12vw 84px 12vw;
    margin-top: 30px;
    background: #F9F9F9;
}

.wired-journey-step-title {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    text-transform: uppercase;
    color: #305C6E;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    font-weight: 500;
}

#journey-road-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}

.journey-road {
    position: relative;
    width: fit-content;
    margin-bottom: 220px;
    margin-left: -50px;
}

.custom-mobile-carousel {
    display: none;
}

@media screen and (max-width: 1200px) {
    .info-section-container {
        width: 60vw;
    }
}

@media screen and (max-width: 1024px) {
    .main-container {
        background-image: none;
        background-color: #F3F5F8;
    }

    .info-section-container {
        width: 100%;
    }

    .quote-container {
        width: 85%;
    }

    .mobile-button {
        width: 100%;
    }

    .input-layout {
        padding-top: 10px;
        padding-bottom: 9px;
    }

    .section-container {
        padding-left: 4.26%;
        padding-right: 4.26%;
    }

    .medium-button {
        padding: 16px 32px;
    }

    .big-button {
        padding: 16px 32px;
        box-shadow: 0 8px 16px 0 #0B203F3D;
    }

    .small-button {
        padding: 12px 24px;
        box-shadow: 0 8px 16px 0 #0B203F1A;
    }

    .small-spaced-title {
        line-height: 36px;
        font-size: 24px;
    }

    .medium-spaced-title {
        line-height: 42px;
        font-size: 28px;
    }

    .big-spaced-title {
        line-height: 54px;
        font-size: 36px;
    }

    .spaced-letters {
        letter-spacing: 1px;
    }

    .presentation-paragraph-20 {
        font-size: 18px;
        line-height: 32px;
    }

    .description-paragraph {
        font-size: 14px;
        line-height: 25px;
    }

    #subscription-paragraph {
        margin-top: 12px;
        margin-bottom: 30px;
    }

    button {
        font-weight: normal;
        width: auto;
    }

    .wire-golden-button {
        box-shadow: none;
    }

    .expertise-card {
        padding: 30px 35px;
    }

    .expertise-item-width {
        margin: 8px 0;
        max-width: none;
    }

    .more-arrow-container {
        color: #C6B166;
    }

    .more-arrow-container p {
        color: #C6B166;
    }

    .card-upper-triangle {
        border-bottom-width: calc(2vw + 25px);
        border-left-width: calc(10vw + 35px)
    }

    .card-left-triangle {
        border-left-width: calc(2vw + 25px);
        border-bottom-width: calc(10vw + 35px);
    }

    .news-container {
        margin-bottom: 60px;
    }

    .news-title-container {
        width: 90%;
    }

    #news-card-container {
        grid-template-columns: auto;
        margin-bottom: 20px;
    }

    .news-card {
        max-width: none;
    }

    .big-news-card {
        min-height: 340px;
        grid-row: auto;
    }

    .small-news-card {
        min-height: 220px;
    }

    #centered-news-card {
        grid-row: auto;
    }

    .card-title {
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .card-tag {
        font-weight: 500;
        letter-spacing: 1px;
    }

    #view-all {
        font-size: 14px;
    }

    .card-text-container {
        padding: 24px 24px;
    }

    .subscribe-container {
        display: flex;
        justify-content: center;
        padding-top: 45px;
        padding-bottom: 55px;
        background-color: #F3F5F8;
    }

    #subscribe-info-mobile-container {
        width: 90%;
    }

    .email-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
    }

    #email-input {
        margin-right: 0;
        margin-bottom: 0;
        width: 100%;
        font-size: 14px;
        padding-top: 10px !important;
        padding-bottom: 9px !important;
    }

    #mobile-subscribe-button {
        display: block;
        z-index: 1000;
        position: absolute;
        right: 0;
        top: 0;
    }

    #subscribe-button {
        display: none;
    }

    .subscription-vertical-triangle-container {
        height: auto;
    }

    .horizontal-triangle {
        border-left-width: calc(20vw + 20px);
        border-bottom-width: calc(5vw + 20px);
    }

    .subscription-vertical-triangle-container .vertical-triangle {
        border-bottom-width: calc(20vw + 20px) !important;
        border-left-width: calc(5vw + 20px);
    }

    .wired-journey {
        width: 100%;
        padding: 60px 16px 0 16px;
        margin-top: 20px;
        background: none;
    }

    #journey-road-container > button {
        display: none;
    }

    .custom-mobile-carousel {
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        height: auto;
        padding-top: 40px;
        margin: 0 -16px;
        align-items: center;
        justify-content: center;
    }

    .custom-mobile-carousel .carousel {
        width: 100vw;
    }

    .custom-carousel-details {
        text-align: start;
        padding-bottom: 20px;
    }

    .custom-mobile-carousel .step {
        margin-top: 0;
    }

    .custom-mobile-carousel #contact-step {
        width: 85%;
        margin-bottom: 25px;
        margin-top: 0;
    }

    #custom-carousel-image-container {
        width: 100%;
        overflow: hidden;
    }

    #custom-carousel-image-container img {
        transition: all ease-in-out 0.6s;
    }

    #uk-mobile-carousel-image {
        transform: translateX(47%);
    }

    #role-mobile-carousel-image {
        transform: translateX(47%);
    }

    #us-mobile-carousel-image {
        transform: translateX(48%);
    }

    #oxbridge-mobile-carousel-image {
        transform: translateX(48%);
    }

    #med-app-mobile-carousel-image {
        transform: translateX(47%);
    }

    #post-app-mobile-carousel-image {
        transform: translateX(47%);
    }

    .custom-mobile-carousel .carousel-control-prev, .custom-mobile-carousel .carousel-control-next {
        top: auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    #custom-carousel-image-container, #custom-carousel-image-container img {
        width: 200%;
    }

    #role-mobile-carousel-image {
        transform: translateX(22%);
    }

    #uk-mobile-carousel-image {
        transform: translateX(17.5%);
        width: 250% !important;
    }

    #us-mobile-carousel-image {
        transform: translateX(15%);
        width: 300% !important;
    }

    #oxbridge-mobile-carousel-image {
        transform: translateX(15%);
        width: 300% !important;
    }

    #med-app-mobile-carousel-image {
        transform: translateX(23.5%);
    }

    #post-app-mobile-carousel-image {
        transform: translateX(22%);
    }

}

@media screen and (max-width: 550px) {
    .card-upper-triangle {
        border-bottom-width: calc(4vw + 25px);
        border-left-width: calc(20vw + 35px)
    }

    .card-left-triangle {
        border-left-width: calc(4vw + 25px);
        border-bottom-width: calc(20vw + 35px);
    }
}


/* The Modal (background) */
.modal-container {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    right: 0;
    width: 100%;
    height: 100%;
    top: 0;
    overflow-y: auto; /* Enable scroll if needed*/
    overflow-x: hidden;
    z-index: 2000;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background: #FFFFFF;
    border: none;
    max-width: 640px;
    height: 100%;
    z-index: 999999;
    overflow-y: auto;
    left: calc(100% - 640px);
    border-radius: 0;
}

/* The Close Button */
.close {
    color: #A9955E;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}


.modal-title-consultant {
    padding-top: 68px;
    font-family: Chronicle Display;
    font-weight: bold;
    font-style: normal;
    font-size: 36px;
    line-height: 43px;

    color: #305C6E;
}


.advise-text {

    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    /* or 29px */

    padding-top: 16px;
    color: #515151;
}

.modal-email-address {
    position: absolute;
    width: 189px;
    height: 17px;
    left: 64px;
    top: 336px;

    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 1px;
    text-transform: uppercase;

    color: #A9955E;

}

.modal-golden-hr-email {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    width: 85%;
    margin-top: 50px;
    border: none;
    border-bottom: 1px solid #A9955E;
}

.modal-golden-hr-phone {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    width: 85%;
    margin-top: 50px;
    border: none;
    border-bottom: 1px solid #A9955E;
}

.modal-golden-hr-message {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    width: 85%;
    margin-top: 50px;
    border: none;
    border-bottom: 1px solid #A9955E;
}

.modal-golden-hr-preferred {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    width: 85%;
    margin-top: 108px;
    border: none;
    border-bottom: 1px solid #A9955E;
}

.modal-golden-hr-email::placeholder, .modal-golden-hr-phone::placeholder, .modal-golden-hr-message::placeholder, .modal-golden-hr-preferred::placeholder, .change-color-golden, .modal-golden-hr-phone-number::placeholder, .modal-golden-hr-email-register::placeholder, .modal-golden-hr-first-name::placeholder, .golden-line::placeholder, .modal-golden-hr-last-name::placeholder {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 1px;
    text-transform: uppercase;

    color: #A9955E;

}

.modal-golden-hr-email:focus, .modal-golden-hr-phone:focus, .modal-golden-hr-message:focus, .modal-golden-hr-preferred:focus, .change-color-golden, .modal-golden-hr-first-name:focus, .golden-line:focus, .modal-golden-hr-phone-number:focus, .modal-golden-hr-email-register:focus, .modal-golden-hr-last-name:focus {
    border: none;
    background-color: transparent;
    outline: none;
    border-bottom: 1px solid #A9955E;
}


option[value=""][disabled] {
    display: none;
}

.b {
    color: black;
}

.modal-news-register {
    /* Frame 5 */


    /* Auto Layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    width: 255px;
    left: 64px;
    top: 770px;
    height: 65px;
    border: none;
    background: linear-gradient(90deg, #C6B166 0%, #A9955E 100%);
    border-radius: 50px;

    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 2px;
    text-transform: uppercase;

    color: #FFFFFF;
    /* Inside Auto Layout */

    flex: none;
    order: 1;
    flex-grow: 0;

}

.modal-consultant-submit {
    /* Frame 5 */


    /* Auto Layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    width: 255px;
    height: 65px;
    margin-top: 88px;
    border: none;
    background: linear-gradient(90deg, #C6B166 0%, #A9955E 100%);
    border-radius: 50px;

    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 2px;
    text-transform: uppercase;

    color: #FFFFFF;
    /* Inside Auto Layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin-bottom: 50px;

}

.news-modal-register-form-container {
    padding-left: 44px;
    padding-right: 44px;
    padding-top: 35px;
}

.news\&resources-register-title {
    font-family: Chronicle Display;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 43px;
    color: #305C6E;
}

.news\&resources-register-details {
    height: 175px;
    border: 1px solid #E0E6EA;
    box-sizing: border-box;
    border-radius: 5px;
}

.news\&resources-register-prep-day {
    position: static;
    width: 452px;
    height: 32px;
    left: 12px;
    top: 12px;

    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 180%;
    /* or 32px */

    text-transform: uppercase;

    color: #305C6E;


    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px 10px 10px;
}

.news\&resources-register-date {
    position: static;
    width: 200px;
    height: 50px;
    left: 12px;
    top: 54px;

    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    /* or 25px */

    letter-spacing: 1px;

    color: #515151;


    /* Inside Auto Layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 10px 0px 10px 10px;

}

.news\&resources-register-limit {
    position: static;
    width: 332px;
    height: 50px;
    left: 12px;
    top: 114px;

    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    /* or 25px */


    color: #305C6E;


    /* Inside Auto Layout */

    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 10px 0px 10px 10px;
}

.modal-golden-hr-student_guardian, .golden-dropdown {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    width: 526px;
    height: 30px;
    top: 336px;
    border: none;
    border-bottom: 1px solid #A9955E;
    margin-top: 52px;
}

.modal-golden-hr-first-name, .golden-line {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    width: 526px;
    height: 30px;
    top: 336px;
    border: none;
    border-bottom: 1px solid #A9955E;
    margin-top: 41px;
}

.modal-golden-hr-last-name {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    width: 526px;
    height: 30px;
    top: 336px;
    border: none;
    border-bottom: 1px solid #A9955E;
    margin-top: 41px;
}

.modal-golden-hr-email-register {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    width: 526px;
    height: 30px;
    top: 336px;
    border: none;
    border-bottom: 1px solid #A9955E;
    margin-top: 41px;
}

.modal-golden-hr-phone-number {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    width: 526px;
    height: 30px;
    top: 336px;
    border: none;
    border-bottom: 1px solid #A9955E;
    margin-top: 41px;
}

.news\&resources-register-check {
    margin-top: 33px;
    top: 707px;
}

.news\&resources-register-check-label {
    position: absolute;
    width: 191px;
    height: 25px;
    left: 88px;

    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    /* or 25px */

    color: #305C6E;
}

.news\&resources-register-check-radio {
    width: 14px;
    height: 14px;
    left: 62px;
    border: 1px solid #305C6E;
    box-sizing: border-box;
    margin-right: 12px;
    margin-top: 5px;
}

.modal-body {
    z-index: 99999;
    padding: 40px;
}

@media screen and (max-width: 1024px) {
    .news\&resources-register-title {
        font-family: Chronicle Display;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        color: #305C6E;
    }

    .news-modal-register-form-container {
        padding-left: 25px;
        padding-right: 0px;
        padding-top: 40px;
        height: 832px;
    }

    .news\&resources-register-details, .golden-dropdown, .modal-golden-hr-student_guardian, .golden-line, .modal-golden-hr-first-name, .modal-golden-hr-last-name, .modal-golden-hr-email-register, .modal-golden-hr-phone-number {
        width: 95%;
    }

    .news\&resources-register-details {
    }

    .news\&resources-register-prep-day {
        width: 264px;
        height: 46px;
        margin-left: 18px;
        top: 16px;

        font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 180%;
        /* or 23px */

        text-transform: uppercase;

        color: #305C6E;
    }

    .news\&resources-register-limit {
        position: static;
        width: 293px;
        height: 46px;
        margin-left: 18px;
        top: 132px;

        font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 180%;
        color: #305C6E;
    }

    .news\&resources-register-date {
        position: static;
        width: 180px;
        height: 46px;
        margin-left: 18px;
        top: 74px;

        font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 180%;
        /* or 23px */

        letter-spacing: 1px;

        color: #515151;
    }

    .news\&resources-register-check-radio {
        margin-right: 0px;
    }

    .news\&resources-register-check-label {
        margin-left: -30px;
    }

    .close {
        margin-right: 15px;
        margin-top: 15px;
    }

    .modal-consultant-register {
        width: 311px;
        height: 49px;
        margin-left: -40px;
        top: 735px;
    }

    .news-modal-register-form-container .medium-title {
        font-size: 24px !important;
        line-height: 150% !important;
    }

    .modal-news-register {
        top: 730px;
        height: 49px;
        width: 311px;
        margin-left: -40px;
    }
}

.pay-container {
    padding-left: 40px;
    padding-top: 50px;
}

.pay-modal-container {
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    text-align: center;
}

.modal-news-pay {
    /* Frame 5 */


    /* Auto Layout */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    width: 236px;
    height: 44px;
    border: none;
    background: linear-gradient(90deg, #C6B166 0%, #A9955E 100%);
    border-radius: 50px;

    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 2px;
    text-transform: uppercase;

    color: #FFFFFF;
    /* Inside Auto Layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.news\&resources-thank-you {
    height: 43px;
    top: calc(50% - 43px / 2 - 332px);
    font-family: Chronicle Display;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 43px;
    color: #305C6E;
}

.news\&resources-email-sent {
    height: 25px;
    font-family: Sweet Sans Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    /* or 25px */
    color: #305C6E;

}

.news\&resources-learn-container {
    margin-top: 66px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    position: static;
    width: 512px;
    height: 388px;
    left: 64px;
    top: 322px;

    border: 1px solid #E0E6EA;
    box-sizing: border-box;
    border-radius: 5px;
}

.news\&resources-learn-title {
    position: static;
    width: 204px;
    height: 29px;
    right: 154.5px;
    top: calc(50% - 29px / 2 - 160px);
    margin-top: 100px;
    margin-left: 150px;

    font-family: Chronicle Display;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    text-align: center;

    /* Dark blue */

    color: #305C6E;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.news\&resources-learn-items {
    list-style-image: url(/images/check.png);
}

.news\&resources-learn-items li::before {
    margin-right: 30.41px;
    display: inline-block;
    margin-top: 20px;
}

.news\&resources-learn-items li {
    position: relative;
    margin-bottom: 12px;
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    /* or 25px */

    color: #515151;
    padding-left: 30px;
    padding-top: -10px;
    margin-left: -15px;
}

.news\&resources-share {
    width: 41px;
    height: 25px;
    left: 300px;
    top: 778px;

    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    /* or 25px */


    color: #305C6E;

    margin-left: 235px;
    margin-top: 80px;
}

.news\&resources-social-media {
    width: 115.04px;
    height: 17.28px;
    margin-left: 198px;
}

.news\&resources-social-media > img:not(:last-child) {
    margin-right: 24px;
}


.news\&resources-social-media > img {
    height: 20px;
    width: 20px;
}

.news\&resources-carousel-indicators li {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 1;
    margin-top: 100px;
}

.news\&resources-carousel-indicators .active {
    background-color: #8D9BA7
}

.news-slide-show-buttons {
    margin-top: 60px;
}

.modal-pay-mobile {
    display: none;
}

@media screen and (max-width: 1024px) {
    .modal-pay-mobile {
        display: inline-flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        text-align: center;
        margin-top: 50px;
        margin-left: -25px;
    }

    .pay-container {
        height: 812px;
        margin-top: -30px;
    }

    .news\&resources-thank-you {
        font-size: 24px;
        line-height: 150%;
        color: #305C6E;
    }

    .news\&resources-email-sent {
        height: 25px;
        font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 180%;
        /* or 25px */
        width: 220px;

        color: #305C6E;
        display: inline-flex;
        margin-left: -25px;

    }

    .modal-news-pay {
        display: none;
    }

    .news\&resources-learn-container {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;

        position: static;
        width: 311px;
        height: 450px;
        top: 322px;

        border: 1px solid #E0E6EA;
        box-sizing: border-box;
        border-radius: 5px;
        margin-left: -10px;
    }

    .news\&resources-learn-title {
        width: 204px;
        height: 29px;
        right: 154.5px;
        top: calc(50% - 29px / 2 - 160px);
        margin-top: 20px;
        margin-left: 45px;

        font-family: Chronicle Display;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        /* identical to box height */

        text-align: center;

        /* Dark blue */

        color: #305C6E;


        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .news\&resources-learn-items li::before {
        margin-right: 30.41px;
        display: inline-block;
        margin-top: 20px;
    }

    .news\&resources-learn-items li {
        position: relative;
        margin-bottom: 12px;
        font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 180%;
        /* or 25px */

        color: #515151;
        padding-left: 10px;
        padding-top: -10px;
        margin-left: -15px;
    }

    .news\&resources-social-media {
        width: 115.04px;
        height: 17.28px;
        margin-left: 90px;
        margin-top: 55px;
    }

    .news\&resources-share {
        display: none;
    }

    .news\&resources-carousel-indicators li {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.1);
        opacity: 1;
        margin-top: 100px;
    }

    .news\&resources-carousel-indicators {
        position: relative;
        margin-top: -142px;
    }

    .pay-container-add-to-calendar {
        margin-left: 10px;
        font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        /* identical to box height */

        letter-spacing: 1px;
        text-transform: uppercase;

        color: #A9955E;
    }

    .pay-container .medium-title {
        font-size: 24px !important;
        line-height: 150% !important;
    }
}

.distance {
    position: relative;
    top: -6px;
}


.back-to-faq {
    padding-top: 100px;
    width: 145px;
    height: 33px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.back-to-faq-text {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 1px;

    /* Dark blue */

    color: #305C6E;
    padding-top: 6px;
    padding-left: 15px;

}

.faq-details-container {
    background: #FFFFFF;
    border-radius: 16px;
    padding: 42px 65px 44px;

    width: 100%;
    box-sizing: border-box;
    margin-top: 26px;
}

.faq-details-main-title {
    width: 289px;
    height: 43px;
    right: 756px;

    font-family: Chronicle Display;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 43px;
    text-align: center;

    color: #305C6E;
}

.faq-details-question-container {
}

.faq-details-question {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-transform: uppercase;

    /* Dark blue */

    color: #305C6E;
    padding-bottom: 14px;
}

.faq-details-answer {

    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    /* or 25px */

    padding-bottom: 14px;

    /* Dark gray */

    color: #454B51;
}

.faq-details-contact-us-container {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 340px;
    left: 0px;

    background: #305C6E;
}

.faq-details-contact-us-title {
    position: static;
    width: 1110px;
    height: 65px;
    left: 0px;
    top: 0px;

    font-family: Chronicle Display;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 180%;
    /* identical to box height, or 65px */

    text-align: center;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

#faq-details-contact-us-button {
    margin-top: 10px;
}


.about-head-of-carfax-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 390px;
    padding-top: 154px;
    background: #F9F9F9;
}

.about-head-of-carfax-profile-name {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    /* Dark blue */

    color: #305C6E;
    margin-top: 24px;
}


.about-head-of-carfax-profile-quote {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    /* or 25px */
    text-align: center;
    /* Dark gray */
    color: #454B51;
    width: 31.5%;
}

.about-head-of-carfax-social-media {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 170px;
    height: 32px;
    margin-top: 20px;
    /*margin-bottom: 97px;*/
}

.about-head-of-carfax-social-media img:not(:last-child) {
    margin-right: 30px;
}

.about-head-of-carfax-message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
}

.about-head-of-carfax-message-title {
    font-family: Chronicle Display;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 180%;
    /* identical to box height, or 65px */
    /* Dark blue */
    color: #305C6E;
}

.about-head-of-carfax-message-text {
    font-family: "Sweet Sans Pro Reg", 'Roboto Thin', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    /* or 29px */
    /* Dark gray */
    color: #454B51;
    font-style: italic;
}

.about-head-of-carfax-about-container {
    background: #FFFFFF;
    border-radius: 16px;
    margin-top: 60px;
    padding: 35px 63px 57px;
}

.about-head-of-carfax-about-title {
    font-family: Chronicle Display;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 180%;
    /* identical to box height, or 58px */


    /* Dark blue */

    color: #305C6E;
}

.about-head-of-carfax-about-text {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    /* or 29px */


    /* Dark gray */

    color: #454B51;
}

#head-of-carfax-desktop {
    display: block;
}

#head-of-carfax-mobile {
    display: none;
}

.about-head-of-carfax-profile-image {
    width: 190px;
    height: 190px;
}

@media screen and (max-width: 1024px) {
    .about-head-of-carfax-profile-image {
        width: 160px;
        height: 160px;
    }

    #head-of-carfax-desktop {
        display: none;
    }

    #head-of-carfax-mobile {
        display: block;
    }

    .about-head-of-carfax-profile-name {
        font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;

        /* Dark blue */

        color: #305C6E;
    }

    .about-head-of-carfax-profile-quote {
        font-family: Sweet Sans Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 180%;
        /* or 25px */

        text-align: center;

        /* Dark gray */

        color: #454B51;
        width: 311px;
    }


    .about-head-of-carfax-social-media {
        margin-left: 80px;
        margin-bottom: 0px;
    }

    .about-head-of-carfax-about-container {
        padding: 35px 16px 25px;
        margin-top: 0;
    }

    .faq-details-container {
        background: #FFFFFF;
        border-radius: 16px;
        padding: 42px 23px 90px;

        width: 100%;
        box-sizing: border-box;
        margin-top: 120px;
    }

    .about-head-of-carfax-message-container .medium-title {
        font-size: 32px !important;
        line-height: 150% !important;
        padding-left: 30px;
    }

    .about-head-of-carfax-message-text {
        padding-left: 30px;
    }

    .faq-details-container .medium-title {
        font-size: 32px;
        line-height: 38px;
        padding-bottom: 12px;
    }

}


.webinar-modal-container {
    padding: 60px 28px 28px;
}

.webinar-modal-container .medium-title {
    line-height: 43px;
    text-align: left !important;
}

.webinar-box {
    margin-top: 21px;
    padding: 18px 48px 18px 12px;
    border: 1px solid #E0E6EA;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 40px;
}

.webinar-modal-register-prep-day {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 180%;
    /* or 32px */

    text-transform: uppercase;

    color: #305C6E;
}

.webinar-modal-register-prep-date {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    /* or 25px */

    letter-spacing: 1px;

    color: #515151;
    margin-bottom: 0px;
}

.golden-text {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 1px;
    text-transform: uppercase;

    color: #A9955E;
    float: left;
    padding-top: 11px;
}

.golden-input {
    opacity: 0.4;
    border: 1px solid #A9955E;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 40px;
}

.golden-select {
    border: 1px solid rgba(169, 149, 94, 0.4);
    box-sizing: border-box;
    border-radius: 5px;
    width: 74px;
    height: 40px;
    color: #A9955E;
    padding-left: 15px;
}

.golden-select:first-child {
    margin-right: 17px;
}

.golden-select option {
    opacity: 1;
}


.golden-select select {
    border: 1px solid #A9955E;
}

.spacing-golden-elements {
    margin-bottom: 31px;
    height: 40px;
    display: inline-flex;
    width: 100%;
}

.spacing-webinar-modal {
    margin-left: 105px;
}

.radio-button-div {
    margin-top: 100px;
}

.golden-label {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    /* or 25px */


    color: #A9955E;
    padding-left: 12px;
}

.webinar-modal-submit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    width: 206px;
    left: 64px;
    height: 65px;
    border: none;
    background: linear-gradient(90deg, #C6B166 0%, #A9955E 100%);
    border-radius: 50px;

    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 2px;
    text-transform: uppercase;

    color: #FFFFFF;
    /* Inside Auto Layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 77px;
}


.carfax-education-container {
    padding: 50px 28px 28px;
}

.carfax-education-title {
    font-family: Chronicle Display;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 150%;
    /* or 54px */
    color: #305C6E;
}

.carfax-education-container .medium-title {
    margin-bottom: 0px !important;
    font-weight: 900 !important;
    padding-right: 194px;
}

.normal-text {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    /* identical to box height, or 29px */
    color: #515151;
}

.carfax-education-enquiry-container .form-check-label {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #A9955E;
    text-transform: none !important;
}

.carfax-education-submit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    width: 302px;
    height: 65px;
    border: none;
    background: linear-gradient(90deg, #C6B166 0%, #A9955E 100%);
    border-radius: 50px;
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 30px;
}

.smaller-golden-dropdown {
    width: 245px;
    margin-right: 33px;
}

.carfax-education-enquiry-container {
    padding-left: 24px;
    padding-bottom: 12px;
}

.carfax-education-enquiry-container .medium-title {
    color: #305C6E;
    font-size: 36px;
    font-weight: 600;
    font-family: "HCo Chronicle Display";
    line-height: 150%;
    margin-bottom: 0;
    width: 60%;
}

.carfax-education-enquiry-container .golden-line, .carfax-education-enquiry-container .golden-dropdown {
    margin-top: 36px;
}

.carfax-education-enquiry-container .golden-line:first-child {
    margin-top: 10px;
}

.get-in-touch-status {
    position: fixed;
    top: 80px;
    width: 97%;
}

.subscribe-status {
    position: fixed;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 97%;
    z-index: 4;
}

.get-in-touch-container {
    background-color: #F9F9F9;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 54px;
    margin-top: 83px;
}

.get-in-touch-main-container .school-presentation-image {
    width: 482px;
    height: 177px;
}

.get-in-touch-main-container .presentation-wrapper {
    flex-direction: column;
}

.get-in-touch-main-container .big-title {
    text-align: center;
}

.get-in-touch-main-container #get-in-touch-presentation-width {
    margin-top: 35px;
    width: 100%;
    align-items: center;
}

.get-in-touch-main-container #get-in-touch-presentation-width .presentation-paragraph-24 {
    max-width: 580px;
    text-align: center;
}

.get-in-touch-form-container {
    padding: 48px 65px 69px;
    background: #FAFAFA;

    font-family: "Sweet Sans Pro Reg", 'Roboto Thin', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #305C6E;
}

.get-in-touch-main-container .office-map-container {
    display: flex;
    justify-content: space-evenly;
    padding: 0 12vw;
}

.get-in-touch-main-container .office-map-container.map-1, .get-in-touch-main-container .office-map-container.map-3 {
    flex-direction: row-reverse;
}

.get-in-touch-main-container .office-map-container.map-0, .get-in-touch-main-container .office-map-container.map-2 {
    background-color: #FAFAFA;
}

.get-in-touch-main-container .office-container {
    width: 100vw;
}

.get-in-touch-main-container .office-container .map-title {
    padding: 0 12vw;
}

.get-in-touch-main-container .office-map {
    width: 50vw;
    height: 400px;
    display: flex;
    align-items: center;
}

.get-in-touch-main-container .office-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.get-in-touch-main-container .map-0 .office-map, .get-in-touch-main-container .map-2 .office-map {
    justify-content: end;
}

.get-in-touch-main-container .flag-container {
    width: 100px;
    margin-bottom: 36px;
}

.get-in-touch-main-container .flag-container img {
    width: 100%;
}

.get-in-touch-main-container .office-title {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #305C6E;
}

.get-in-touch-main-container .office-address {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    color: #454B51;
    text-align: center;
}

.get-in-touch-main-container .office-phone {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    color: #A9955E;
}

.get-in-touch-form-container .get-in-touch-phone, .carfax-education-enquiry-container .get-in-touch-phone {
    display: flex;
    flex-direction: column;
}

.get-in-touch-form-container .get-in-touch-phone input {
    height: 60px !important;
    width: 100% !important;
}

.carfax-education-enquiry-container input {
    border: 1px solid #C9D3D8;
}

.carfax-education-enquiry-container .get-in-touch-phone input {
    height: 51px !important;
    width: 100% !important;
}

.carfax-education-enquiry-container input:not([type=checkbox]) {
    width: calc(100% - 26px);
}

/*.get-in-touch-phone .iti__flag-container{*/
/*    margin: 5px;*/
/*}*/

.get-in-touch-form-container .form-group {
    flex-basis: 47%;
}

.get-in-touch-form-container input, .get-in-touch-form-container textarea {
    border: 1px solid #C9D3D8;
    border-radius: 4px;
    background: #FAFAFA;
    color: #305C6E;
    letter-spacing: 1px;
}

.get-in-touch-form-container input[type=text], .get-in-touch-form-container input[type=email], .get-in-touch-form-container input[type=tel] {
    height: 46px;
    width: calc(100% - 26px);
}

.get-in-touch-container input:focus, .get-in-touch-container textarea:focus, .carfax-education-enquiry-container input:focus, .carfax-education-enquiry-container textarea:focus {
    box-shadow: none;
}

.carfax-education-enquiry-container label {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #A9955E;
}

.get-in-touch-padding-left {
    padding-left: 22px;
}

.get-in-touch-textarea {
    resize: none;
}

.checkbox-get-in-touch {
    margin-top: 1px;
}

.label-get-in-touch {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #454B51;
    padding-left: 6px;
    text-transform: none;
}

.error-label-get-in-touch {
    color: red;
    padding-left: 6px;
    display: none;
}

.submit-get-in-touch {
    font-family: "Sweet Sans Pro Reg", 'Roboto Thin', sans-serif;
    font-weight: 600;
    margin-top: 66px;
}

.get-in-touch-form-line {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;
}

.get-in-touch-container .get-in-touch-checkbox-container {
    margin-bottom: 24px;
}

.textarea-width {
    width: calc(100% - 26px);
}

.security {
    width: 74px;
}

.vision-globe {
    width: 200%;
    height: 400px;
    overflow: hidden;
}

.on-demand-webinars {
    background-color: white;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.on-demand-video {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 50px 12vw;
    width: calc(100% - 24vw);
}

.thank-you-container {
    margin-top: 72px;
    height: calc(100vh - 396px);
}

.thank-you-container .presentation-paragraph-24 {
    max-width: 70%;
    text-align: center;
}

@media screen and (max-width: 1125px) {

    .on-demand-video {
        flex-direction: column;
        align-items: start;
        padding: 30px 12vw;
    }

    .on-demand-video > div {
        width: 70%;
    }

    .on-demand-video > div:not(:first-of-type) {
        display: flex;
    }

}

@media screen and (max-width: 1024px) {
    .vision-globe {
        width: 100%;
    }

    .thank-you-container {
        margin-top: 56px;
        height: 100vh;
        align-items: flex-start;
    }
}

.modal-profile {
    position: fixed; /* Stay in place */
    display: none;
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    z-index: 2000;
    overflow-y: auto;
}

.modal-content-profile {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 77%;
    border-radius: 16px;

}

.modal-body-profile {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 39px;
}

.profile-pop-up-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 180%;
    /* or 43px */

    padding-top: 34px;
    /* Dark blue */

    color: #305C6E;
    font-family: "HCo Chronicle Display";
}

.profile-pop-up-education {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 180%;
    /* or 43px */

    padding-top: 17px;
    /* Dark blue */

    color: #305C6E;
    font-family: "HCo Chronicle Display";
}

.profile-pop-up-text {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    /* or 29px */


    /* Dark gray */

    color: #454B51;
}

.profile-pop-up-university {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 1px;
    text-transform: uppercase;

    /* Dark blue */

    color: #305C6E;
    margin-bottom: 8px;

}

.profile-pop-up-university-specialization {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    /* Dark gray */

    color: #454B51;

}

.icon-distance {
    margin-right: 34px;
}

.profile:hover {
    cursor: pointer;
}

.close-profile-pop-up {
    margin-right: -40px;
}

.modal-container-ex {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2000;
}

.register-modal {
    width: 414px;
    padding: 50px 32px 26px 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #C1C7CE;
    position: relative;
    height: 100vh;
    overflow-y: auto;
}

@media screen and (max-width: 1024px) {
    .close-profile-pop-up {
        margin-right: 10px !important;
    }

    .modal-body-profile {
        padding-left: 0px;
        padding-right: 0px;
    }

    .consultant-modal {
        padding-bottom: 25px;
    }
}

@media screen and (max-width: 424px) {
    .education-checkbox {
        font-size: 11px;
    }
}

.webinar-left-text {
    width: 50%;
}

.webinar-right-input {
    width: 50%;
}

@media screen and (max-width: 424px) {
    .golden-select {
        width: 60px;
        padding-left: 9px;
    }

    .security {
        width: 60px;
    }
}

.nav-container {
    text-align: center;
    margin-right: 80px;
}

.nav-container-image {
    height: 84px;
}

.partner-carousel {
    position: relative !important;
    margin-top: 10px;
}

.centered-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 38px auto 0;
}

.spear-index-grid-container {
    display: flex;
}

.spear-index-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px 27px;
    margin-left: 25px;
}

.grid-element {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    min-height: 422px;
    height: 100%;
    padding-bottom: 22px;
    padding-left: 33px;
    padding-right: 32px;
    max-height: 350px;
}

.show-more-cards-school-index {
    display: none;
}

.non-display-cards {
    display: none;
}

.show-more:hover {
    text-decoration: underline;
    cursor: pointer;
}

.center-image-container {
    display: flex;


    align-items: center;


    justify-content: center;
}

.college-name {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    text-transform: uppercase;

    /* Dark blue */

    color: #305C6E;
    margin-bottom: 0px;
}

.college-details {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    /* or 29px */


    /* Dark gray */

    color: #454B51;
    margin-bottom: 0px;
}

.text-down {

    justify-content: flex-start;
}

.image-center-container {
    height: 40%;
    display: flex;
    padding-top: 10px;
}

.center-image {
    align-items: self-end;
    margin: auto;
    height: 90%;
}

.school-index-button:after {

    content: url(/images/schools/dropdown-arrow-school-index.png);
    border: none;
    vertical-align: 0;
}

.school-index-button {
    background: #FFFFFF;
    padding: 18px 0px 15px 30px;
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: 0.01em;
    text-transform: uppercase;
    /* Dark gray */
    color: #454B51;
    width: 100%;
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;
    box-sizing: border-box !important;
    border-bottom: 1px solid #EDEDED;
    border-radius: 0px;
}

.active-school-index-button:after {
    transform: scaleY(-1) !important;
}

.dropdown-school-index {
    margin-right: 25px;
}

.active-school-index-dropmenu {
    display: block !important;
}

.school-index-dropdown {
    display: none;
    background: #FFFFFF;
    padding: 18px 0px 15px 30px;
}

.school-index-label {
    margin-left: 10px;
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    /* Dark gray */

    color: #454B51;
    margin-bottom: 0px;

}

.checkbox-container {
    display: flex;
    margin-bottom: 10px;
}

.checkbox-container:last-child {
    margin-bottom: 0px;
}

.school-index-dropddown-container {
    width: 29%;
    overflow-y: scroll;
    max-height: 700px;
}

.reset-school-index {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #8D9BA7;
    cursor: pointer;
}

.reset-school-index-container {
    background: #FFFFFF;
    padding: 30px 0px 30px 30px;
}

.show-more-container {
    display: flex;
    text-align: center;
    margin-top: 75px;
}

.consultants-hover:hover {
    color: #A9955E;
    text-decoration: none;
}

.consultants-hover {
    margin: 0px !important;
}

.show-more-text {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: #315669 !important;
    text-decoration: none !important;
    cursor: pointer;
}

.show-more-text:hover {
    color: #A9955E !important;
}

.container-school-index {
    display: block;
    position: relative;
    padding-left: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container-school-index input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark-school-index {
    position: absolute;
    top: 0px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #ffffff;
    border: 1px #89969F solid;
    border-radius: 3px;
}

/* On mouse-over, add a grey background color */


/* When the checkbox is checked, add a blue background */
.container-school-index input:checked ~ .checkmark-school-index {
    background-color: #A9955E;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-school-index:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container-school-index input:checked ~ .checkmark-school-index:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-school-index .checkmark-school-index:after {
    left: 6px;
    top: 3px;
    width: 2px;
    height: 6px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.home-link {
    text-decoration: none;
    decoration: none;
    color: black;
}

.home-link:hover {
    text-decoration: none;
    decoration: none;
    color: black;

}

.map-pre-title {
    width: 100%;
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;

    /* blue */

    color: #427C9F;

}

.map-title {
    width: 100%;
    font-family: 'HCo Chronicle Display';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 150%;
    /* identical to box height, or 54px */


    /* Dark blue */

    color: #305C6E;
    padding-bottom: 50px;

}

.filter {
    display: flex;
    display: none;
    margin-top: 50px;
    padding-top: 21px;
    padding-bottom: 20px;
}

.filter-img {
    height: 17px;
    margin-right: 10px;
}

.filter-x {
    height: 17px;
    /*margin-left: 60%;*/
    float: right;
    display: none;
}

.filter-text {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #A9955E;
}

.filter-submit {
    display: none;
}

@media screen and (max-width: 1024px) {
    .spear-index-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px 27px;
        margin-left: 0px;
        width: 100% !important;
    }

    .spear-index-grid-container {
        display: block;
    }

    .show-filters {
        display: block !important;
    }

    .show-filters-reset {
        display: flex !important;
    }

    .filter-submit {
        display: block;
        float: right;
        margin-right: 20px;
        padding: 10px 20px;
        margin-top: -10px;
    }

    .school-index-dropddown-container {
        width: 100%;
    }

    .dropdown-school-index {
        display: none;
    }

    .reset-school-index-container {
        display: none;
    }

    .filter {
        display: flex;
    }

    .filter-x-appear {
        display: block;
    }
}

@media screen and (max-width: 1024px) {

    .residential-map {
        padding-top: 70px;
    }

    #chart-residential-div {
        height: 300px !important;
    }

    .spear-index-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px 27px;
    }

    .spear-index-grid-container {
        display: block;
    }

    .school-index-dropddown-container {
        width: 100%;
    }

    .dropdown-school-index {
        display: none;
    }

    .reset-school-index-container {
        display: none;
    }

    .get-in-touch-main-container {
        background-color: #FAFAFA !important;
        margin-top: 0;
    }

    .get-in-touch-main-container #presentation-container {
        margin-top: 86px;
    }

    .get-in-touch-main-container .school-presentation-image {
        position: relative;
        left: -50px;
    }

    .get-in-touch-main-container .office-map-container {
        flex-direction: column-reverse !important;
        margin-bottom: 80px;
    }

    .get-in-touch-main-container .office-map {
        width: 100%;
        height: 40vh;
        justify-content: center !important;
    }

    .get-in-touch-main-container .office-details {
        padding-left: 32px;
        padding-right: 32px;
    }

    .get-in-touch-main-container .flag-container {
        text-align: center;
    }

    .get-in-touch-main-container .office-map-container.map-0, .get-in-touch-main-container .office-map-container.map-2 {
        background-color: white;
    }

    .get-in-touch-main-container .flag-container img {
        width: 50px;
    }

    .get-in-touch-main-container .office-address, .get-in-touch-main-container .office-phone, .get-in-touch-main-container .office-title {
        font-size: 14px;
    }

    .get-in-touch-main-container .office-container .map-title {
        padding: 0 16px;
    }

    .get-in-touch-main-container .office-map-container {
        padding: 40px 16px;
    }

    #chart-get-in-touch-div {
        height: 300px;
    }

    .get-in-touch-form-container {
        padding: 24px 32px 40px;
    }

    .get-in-touch-form-line {
        flex-direction: column;
    }

    .modal-content {
        left: 0;
        max-width: 100%;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .modal-body {
        padding: 32px 32px 45px;
    }

    .carfax-education-enquiry-container {
        padding: 0;
    }

    .carfax-education-enquiry-container .medium-title {
        font-weight: 400;
        font-size: 28px;
        line-height: 150%;
        width: 70%;
        margin-top: 17px;
    }

    .carfax-education-enquiry-container .normal-text {
        font-weight: 400;
        font-size: 14px;
    }

    .carfax-education-enquiry-container label {
        font-size: 13px;
        line-height: 16px;
    }

    .carfax-education-enquiry-container .form-control {
        padding-top: 0;
        padding-bottom: 0;
    }

    .carfax-education-enquiry-container .get-in-touch-phone input {
        height: 41px !important;
    }

    .map-title {
        padding-bottom: 0;
    }
}

#big-carfax-logo {
    width: 85.33px;
    height: 96px;
}

@media screen and (max-width: 1024px) {
    #big-carfax-logo {
        width: 71.11px;
        height: 80px;
    }
}
